import { configureStore } from "@reduxjs/toolkit";
import authReducer from './reducers/auth'
import userReducer from './reducers/user'
import storage from "redux-persist/lib/storage"
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";

const userPersistConfig = {
    key: "user",
    storage,
    blacklist: {},

}

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
    }
})

export const persistor = persistStore(store)

export default store;
