import axios from 'axios'

const fetchClient = () => {
    let instance = axios.create();
  
    instance.interceptors.request.use((config) => {
      const token = localStorage.getItem("KLAESI_TOKEN");
  
      if (token) config.headers.authorization = token;
  
      return config;
    });
    
  // on catch error
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 || error.response.status === 400) {
        localStorage.removeItem("KLAESI_TOKEN");
        window.location.reload();
      }
      return Promise.reject(error);
    }
  )
  
    return instance;
  };
  
  export const api = fetchClient();
