import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { ChartsWidget3, MixedWidget11, ChartsWidget9 } from '../../../_metronic/partials/widgets';
import { DashboardCardWrapper } from './components/dashboardCardWrapper';
import { Dashboard } from './_models';
import { useEffect, useState } from 'react';
import { getDashboardData } from './_requests';
import { UsersListLoading } from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading';

const DashboardPage = ({
  dashboardData,
}) => {
  return (
    <>
      <DashboardCardWrapper totalRegisteredUser={dashboardData} />
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          {/* Pie chart component */}
          <ChartsWidget9
            className='card-xl-stretch mb-5 mb-xl-8'
            subscriptionData={dashboardData?.data?.subscriptionGraph}
          />
        </div>
        <div className='col-xl-8'>
          {/* Regisered user graph */}
          <ChartsWidget3
            className='card-xl-stretch mb-5 mb-xl-8'
            initalDashboardData={dashboardData}
          />
        </div>
      </div>

      <div className='row gy-5 g-xl-8'>
        <div>
          {/* Sales graph  */}
          <MixedWidget11
            className='mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='400px'
            initialDashboardData={dashboardData}
          />
        </div>
      </div>

    </>
  );
};

const DashboardWrapper = () => {
  const intl = useIntl();

  const [dashboardData, setDashboardData] = useState<Dashboard | undefined>();

  useEffect(() => {
    getDashboardData("weekly", "weekly")
      .then((response) => {
        console.log("check for dashboard wrapper ")
        setDashboardData(response);
      })
      .catch(() => {
        // Handle error if needed
      });
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>

      {!dashboardData && <UsersListLoading position="fixed" top="50%" left="50%" />}
      {dashboardData && <DashboardPage dashboardData={dashboardData} />}
    </>
  );
};

export { DashboardWrapper };
