/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, resetPassword} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useDispatch} from 'react-redux'
import {authActions} from '../../../../redux/reducers/auth'
import {userActions} from '../../../../redux/reducers/user'
import {toast} from 'react-toastify'

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password required minimum 8 characters')
    .max(20, 'Password required maximum 20 characters')
    .matches(/^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,150}$/, 'Password must contain at least one digit and one special character')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'Password required minimum 8 characters')
    .max(20, 'Password required maximum 20 characters')
    .required('Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
})

const initialValues = {
  password: '',
  confirmPassword: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get('resetToken')
  // const {saveAuth, setCurrentUser} = useAuth()
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        // const {data} = await login(values.eail, values.password)

        const {data} = await resetPassword({
          password: values.password,
          confirmPassword: values.confirmPassword,
          token,
        }); 

        toast.success('Password Reset Succesfully! Try login again', {toastId: 'Reset Password Succesful'})
        
        navigate('/auth/login')
        // localStorage.setItem("KLAESI_TOKEN", data.token);
        // dispatch(authActions.login(data?.data))
        // dispatch(userActions.storeUser(data?.data))
        // saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // setCurrentUser(user)
      } catch (error: any) {
        // saveAuth(undefined)
        // setStatus(error.response.data.message)
        setSubmitting(false)
        setLoading(false)
        toast.error((error as any).response.data.message, {toastId: 'Reset Password issue'})

        // if(error.response.data.message.toLowerCase().includes("email")){
        //   formik.setFieldError('email', error.response.data.message)
        // }
        // if(error.response.data.message.toLowerCase().includes("password")){
        //   formik.setFieldError('password', error.response.data.message)
        // }
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}

      {/* begin::Heading */}

      {/* begin::Login options */}

      {/* end::Login options */}

      {/* begin::Separator */}

      {/* end::Separator */}

      {/* {{formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )}} */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>New Password</label>
        <input
          placeholder='New Password'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          type='password'
          name='password'
          autoComplete='off'
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Confirm Password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
