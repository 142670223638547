/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {Dashboard} from '../../../../app/pages/dashboard/_models'
import {getDashboardData} from '../../../../app/pages/dashboard/_requests'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  initialDashboardData: Dashboard | undefined
}

let renderedFirstTime = false;

const MixedWidget11: React.FC<Props> = ({className, chartColor, chartHeight, initialDashboardData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [timePeriod, setTimePeriod] = useState('Weekly')
  const [revenueGraphData, setRevenueGraphData] = useState<Dashboard | undefined>(initialDashboardData)
  const [isLoading, setIsLoading] = useState(false)

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, revenuePeriod, revenueCount))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    if (!renderedFirstTime) {
      setRevenueGraphData(initialDashboardData)
      renderedFirstTime = true
      return
    }

    setIsLoading(true)
    getDashboardData("weekly", timePeriod)
      .then((response) => {
        setRevenueGraphData(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [timePeriod])

  const revenuePeriod = revenueGraphData?.data.revenueGraph.map((item) => item.period) ?? []
  const revenueCount = revenueGraphData?.data.revenueGraph.map((item) => item.revenue) ?? []

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [mode, revenuePeriod, revenueCount])

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>Sales Performance Overview</span>
          </div>
          <div>
            <select
              className='form-select form-select-lg form-select-solid'
              data-control='select2'
              data-placeholder='Select Time Period'
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
            >
              <option value='Weekly'>Weekly</option>
              <option value='Monthly'>Monthly</option>
              <option value='Quarterly'>Quarterly</option>
              <option value='Half-yearly'>Half-yearly</option>
              <option value='Yearly'>Yearly</option>
            </select>
          </div>       
        </div>
        <div className='card-body'>
          {isLoading && <div className='d-flex align-items-center justify-content-center w-100' style={{ height: chartHeight, color: '#5e6278' }}>Loading...</div>}     
          {!isLoading && <div ref={chartRef} className='mixed-widget-10-chart' style={{ height: chartHeight }}></div>}
        </div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, revenuePeriod: string[], revenueCount: (number | null)[]): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      {
        name: 'Revenue',
        data: revenueCount,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%', 
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: revenuePeriod,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Dollars',
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' revenue'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export {MixedWidget11}
