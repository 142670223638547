/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { getDashboardData } from '../../../../app/pages/dashboard/_requests'
import { Dashboard } from '../../../../app/pages/dashboard/_models'

type Props = {
  className: string,
  initalDashboardData: Dashboard | undefined
}

const keys = {
  'weekly': 'day',
  'monthly': 'week',
  'quarterly': 'day',
  'half-yearly': 'month',
  'yearly': 'day',
}

let renderedFirstTime = false;

const ChartsWidget3: React.FC<Props> = ({ className, initalDashboardData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const [timePeriod, setTimePeriod] = useState('Weekly')
  const [totalRegisteredUser, setTotalRegisteredUser] = useState<Dashboard | undefined>(initalDashboardData)
  const [isLoading, setIsLoading] = useState(false)

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, graphCategories, graphCount, keys[timePeriod.toLowerCase()]))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    if (!renderedFirstTime) {
      setTotalRegisteredUser(initalDashboardData)
      renderedFirstTime = true
      return
    }

    setIsLoading(true)
    getDashboardData(timePeriod, "weekly")
      .then((response) => {
        setTotalRegisteredUser(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [timePeriod])

  const graphCategories = totalRegisteredUser?.data.userGraph.map((item) => item[keys[timePeriod.toLowerCase()]]) ?? []
  const graphCount = totalRegisteredUser?.data.userGraph.map((item) => item.count) ?? []

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [mode, graphCategories, graphCount])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Registered Users Trend</span>
        </h3>
        <div>
          <select
            className='form-select form-select-lg form-select-solid'
            data-control='select2'
            data-placeholder='Select Time Period'
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
          >
            <option value='Weekly'>Weekly</option>
            <option value='Monthly'>Monthly</option>
            <option value='Quarterly'>Quarterly</option>
            <option value='Half-yearly'>Half-yearly</option>
            <option value='Yearly'>Yearly</option>
          </select>
        </div>
      </div>

      <div className='card-body'>       
        {isLoading && <div className='d-flex align-items-center justify-content-center w-100' style={{ height: '350px', color: '#5e6278' }}>Loading...</div>}     
        {!isLoading && <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>}
      </div>
    </div>
  )
}

export { ChartsWidget3 }

function getChartOptions(height: number, graphCategories: string[], graphCount: (number | null)[], period: string | undefined): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  return {
    series: [
      {
        name: 'Registered Users',
        data: graphCount,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: graphCategories,
      title: {
        text: period ? `Period (${period})` : 'Period',
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Users',
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (val) {
          return Math.round(val).toString()
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toString()
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
