import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { api as customApi} from "../../../../axios_config"
const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/me/profile`
export const LOGIN_URL = `${API_URL}/admin/me/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/admin/me/forgot-password/check-email`
export const CHANGE_PASSWORD_URL = `${API_URL}/admin/me/change-password`
export const UPDATE_PROFILE_URL = `${API_URL}/admin/me/profile`
export const GET_PROFILE_DETAIL = `${API_URL}/admin/me/profile`
export const RESET_PASSWORD_URL = `${API_URL}/admin/me/forgot-password/reset-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<any>(LOGIN_URL, {
    email,
    password,
  })
}

const fetchClient = () => {
  let instance = axios.create();

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("KLAESI_TOKEN");

    if (token) config.headers.authorization = token;

    return config;
  });

  return instance;
};

export const api = fetchClient();

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return api.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestPassword(email: string) {
  return api.post<any>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function changePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
  return api.post<{result: any}>(CHANGE_PASSWORD_URL, {
    oldPassword, newPassword, confirmPassword
  })
}

export function getUserByToken(token: string) {
  return customApi.get(GET_USER_BY_ACCESSTOKEN_URL)
}

export function getUserProfile() {
  return customApi.get(GET_USER_BY_ACCESSTOKEN_URL)
}

export function updateProfile(firstName: string, lastName: string, email: string) {
  return api.put<any>(UPDATE_PROFILE_URL, {
    firstName,
    lastName,
    email,
  })
}

export const getProfileDetails = () => {
  return api.get<any>(GET_PROFILE_DETAIL);
}

export const resetPassword = (data: any) => {
  return api.post<any>(RESET_PASSWORD_URL, data)
}