import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  userInfo: {},
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    storeUser(state, action) {
      state.userInfo = action.payload

      return state
    },
    removeUser(state, action) {
        state.userInfo = action.payload
        return state
    }
  },
})

export const userActions = userSlice.actions;

export default userSlice.reducer;
