type Position = 'absolute' | 'fixed';

interface UsersListLoadingProps {
  position?: Position;
  top?: string;
  left?: string;
}

const UsersListLoading: React.FC<UsersListLoadingProps> = ({
  position = 'absolute',
  top = 'calc(50% - 6rem)',
  left = 'calc(50% - 4rem)',
}) => {

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#0B0C29',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top, 
    left, 
  };

  return (
    <div style={{ ...styles, position, textAlign: 'center' }}>
      Processing...
    </div>
  );
};

export { UsersListLoading };
