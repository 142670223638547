import { createSlice } from "@reduxjs/toolkit";

const AUTH_TOKEN:string  = "KLAESI_TOKEN";


const token = localStorage.getItem(AUTH_TOKEN);

const initialState = {
  loggedIn: Boolean(token),
  token : token || ""
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
      login(state, action) {
        state.loggedIn = true;
  
        return state;
      },
  
      logout(state) {
        state.loggedIn = false;
  
        return state;
      },
    },
  });
  
  export const authActions = authSlice.actions;
  
  export default authSlice.reducer;

  

