/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string | number | undefined
  descriptionColor?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
}) => {
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body'>
        <div className='d-flex align-items-center gap-3 mb-3'>
          <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />
          <div className={`text-${titleColor} fw-bold fs-3`}>{title}</div>
        </div>
        <div className={`fw-semibold fs-1 fw-bold ms-1 text-${descriptionColor}`}>{description}</div>
      </div>
    </a>
  )
}

export {StatisticsWidget5}
