/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      
      <AsideMenuItem to='/apps/user-management/users' icon='shield-tick' title='User management' />
      <AsideMenuItem to="/apps/tips" icon='brifecase-tick' title='Tips Management'/>
      {/* <AsideMenuItem to="/crafted/pages/wizards/horizontal" icon='brifecase-tick' title='Subscription Management' /> */}
      <AsideMenuItem to="crafted/pages/subscription-details" icon='brifecase-tick' title='Subscription Management'/>


      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-8'></div>
        </div>
      </div>
    </>
  )
}
