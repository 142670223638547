import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils';
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider';
import { Dashboard } from '../../../../app/pages/dashboard/_models';


type Props = {
  className: string;
  subscriptionData?: Dashboard['data']['subscriptionGraph'];
};

const ChartsWidget9: React.FC<Props> = ({ className, subscriptionData }) => {

  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const seriesData = subscriptionData ? [
    subscriptionData.basicPercentage,
    subscriptionData.freePercentage,
    subscriptionData.trialPercentage,
    subscriptionData.vipPercentage
  ] : [];

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, seriesData));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, seriesData]);

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Subscription Distribution</span>
          <span className='text-muted fw-semibold fs-7'>% distribution of users across plans</span>
        </h3>
      </div>
      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_2_chart' style={{ height: '350px' }}></div>
      </div>
    </div>
  );
};

function getChartOptions(height: number, seriesData: number[] | undefined) {
  const labelColor = getCSSVariableValue('--bs-gray-900');
  const borderColor = getCSSVariableValue('--bs-gray-700');
  const baseColor = getCSSVariableValue('--bs-gray-500');
  const secondaryColor = getCSSVariableValue('--bs-gray-300');

  return {
    series: seriesData,
    chart: {
      type: 'pie',
      height: height,
    },
    labels: ['Basic', 'Free', 'Trial', 'Vip'],
    colors: [labelColor, borderColor, baseColor, secondaryColor],
    legend: {
      show: true,
      position: 'bottom',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + '%';
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toFixed(2) + '%';
        },
      },
    },
  };
}

export { ChartsWidget9 };
