import { useState, useEffect } from "react";
import { StatisticsWidget5 } from "../../../../_metronic/partials/widgets"
import { getDashboardData } from "../_requests";
import { Dashboard } from "../_models";


const DashboardCardWrapper = ({totalRegisteredUser}) => {
  console.log('card render')
  
    return(
        <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='profile-user'
            color='body-white'
            iconColor='primary'
            title='Total Registered Users'
            description={totalRegisteredUser?.data?.totalUser}
            titleColor='gray-400'
            descriptionColor='gray-800'
          />
        </div>
  
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='flag'
            color='primary'
            iconColor='white'
            title='Flagged Profiles'
            description={totalRegisteredUser?.data?.flaggedUser}
            titleColor='white'
            descriptionColor='white'
          />
        </div>
  
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='graph-2'
            color='dark'
            iconColor='gray-100'
            title='Sales Stats'
            description={totalRegisteredUser?.data?.totalRevenue}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
      </div>
    )
}

export { DashboardCardWrapper }