import { AxiosResponse } from 'axios';
import { api } from '../../../axios_config';
import { Dashboard } from './_models';

const API_URL = process.env.REACT_APP_THEME_API_URL;
const GET_DASHBOARD_URL = `${API_URL}/dashboard`;

const getDashboardData = (  usersTimePeriod: string = 'weekly', salesTimePeriod: string = 'weekly'): Promise<Dashboard> => {
    let url = `${GET_DASHBOARD_URL}?filter=${usersTimePeriod.toLowerCase()}&subsFilter=${salesTimePeriod.toLowerCase()}`;
    return api.get(url)
        .then((response: AxiosResponse<Dashboard>) => response.data);
};

export { getDashboardData };
